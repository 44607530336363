footer
  width 1280px
  display flex
  position absolute
  bottom 0
  top auto
  left calc(50% - 640px)
  .container
    display flex
    flex-direction column
    .first-line, .second-line
      display flex
      flex-direction row
      .photo, .contacts
        width 200px
        height 200px
        min-width 200px
        img
          width 200px
          height 200px
      .contacts
        p
          position relative
          margin-left 20px
          line-height 32px
          &:before
            width 22px
            height 22px
            content ""
            position absolute
            left -24px
            margin-top 5px
        p.phone:before
          background-image url("/static/dist/img/svg/ios-phone-portrait.svg")
        p.address:before
          background url("/static/dist/img/svg/ios-pin.svg")
        p.email:before
          background url("/static/dist/img/svg/ios-mail.svg")
      .full-name, .about
        width auto
        margin 20px
        .name
          p
            font-size 16px
        .profession
          p
            font-size 12px
